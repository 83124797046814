var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Change Locale"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeChange) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Your Current Locale: "), _c('strong', [_vm._v(_vm._s(_vm.locale))])]), _c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('b-form-radio-group', {
    staticClass: "demo-inline-spacing",
    attrs: {
      "id": "radio-group-2",
      "name": "radio-sub-component"
    },
    model: {
      value: _vm.locale,
      callback: function callback($$v) {
        _vm.locale = $$v;
      },
      expression: "locale"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "en"
    }
  }, [_vm._v(" English ")]), _c('b-form-radio', {
    attrs: {
      "value": "fr"
    }
  }, [_vm._v(" French ")]), _c('b-form-radio', {
    attrs: {
      "value": "de"
    }
  }, [_vm._v(" German ")]), _c('b-form-radio', {
    attrs: {
      "value": "pt"
    }
  }, [_vm._v(" Portuguese ")])], 1)], 1), _c('div', {
    staticClass: "border rounded mt-3 p-2"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('message.title')) + " ")]), _vm._v(" " + _vm._s(_vm.$t('message.text')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }